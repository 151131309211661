import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Text,
  Flex,
} from "@chakra-ui/react";

import { ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { useState } from "react";

export const AdminManageMenu = (props) => {
  const classLevel = props.classlevel;
  const [isSchoolsOpen, setIsSchoolsOpen] = useState(false);
  const [isDepartmentsOpen, setIsDepartmentsOpen] = useState(false);
  const [isPIcustomersOpen, setIsPIcustomersOpen] = useState(false);
  const [isEventsOpen, setIsEventsOpen] = useState(false);
  const [isCalendarsOpen, setIsCalendarsOpen] = useState(false);
  const [isInstrumentsOpen, setIsInstrumentsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isComputerInfoOpen, setIsComputerInfoOpen] = useState(false);
  const [isUsersOpen, setIsUserOpen] = useState(false);
  const [isRequestsOpen, setIsRequestsOpen] = useState(false);
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const [isCenterInformationOpen, setIsCenterInformationOpen] = useState(false);
  const [isWebPageOpen, setIsWebPageOpen] = useState(false);
  const [isInstrumentOpen, setIsInstrumentOpen] = useState(false);
  const [isInstrumentWebPageOpen, setIsInstrumentWebPageOpen] = useState(false);
  const [isStaffMembersOpen, setIsStaffMembersOpen] = useState(false);
  const [isAdvisorCommitteOpen, setIsAdvisorCommiteOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isNewsOpen, setIsNewsOpen] = useState(false);
  const [isCareersOpen, setIsCareersOpen] = useState(false);
  const [isEquipmentsOpen, setIsEquipmentsOpen] = useState(false);
  const [isCapabilitiesOpen, setIsCapabilitiesOpen] = useState(false);

  const LinkText = ({ children, color, ...props }) => {
    const colorValue = color;
    return (
      <Text
        as="span"
        fontSize={["lg", "lg", "md"]}
        fontWeight="bold"
        color={colorValue}
        {...props}
      >
        {children}
      </Text>
    );
  };

  const handlePIcustomersToggle = () => {
    setIsPIcustomersOpen(!isPIcustomersOpen);
    setIsCalendarsOpen(false);
    setIsInstrumentsOpen(false);
    setIsUserOpen(false);
    setIsCenterInformationOpen(false);
    setIsWebPageOpen(false);
  };
  const handleWebPageToggle = () => {
    setIsWebPageOpen(!isWebPageOpen);
    setIsCalendarsOpen(false);
    setIsInstrumentsOpen(false);
    setIsUserOpen(false);
    setIsCenterInformationOpen(false);
    setIsPIcustomersOpen(false);
    setIsInstrumentWebPageOpen(false);
  };
  const handleSchoolsToggle = () => {
    setIsSchoolsOpen(!isSchoolsOpen);
  };

  const handleInstrumentWebPageToggle = () => {
    setIsInstrumentWebPageOpen(!isInstrumentWebPageOpen);
  };
  const handleInstrumentsToggle = () => {
    setIsInstrumentOpen(!isInstrumentOpen);
    setIsStaffMembersOpen(false);
    setIsAdvisorCommiteOpen(false);
    setIsPublicationsOpen(false);
    setIsNewsOpen(false);
    setIsCareersOpen(false);
    setIsEquipmentsOpen(false);
    setIsCapabilitiesOpen(false);
  };
  const handleStaffMembersToggle = () => {
    setIsStaffMembersOpen(!isStaffMembersOpen);
    setIsInstrumentOpen(false);
    setIsAdvisorCommiteOpen(false);
    setIsPublicationsOpen(false);
    setIsNewsOpen(false);
    setIsCareersOpen(false);
    setIsEquipmentsOpen(false);
    setIsCapabilitiesOpen(false);
  };
  const handleAdvisorCommitteToggle = () => {
    setIsAdvisorCommiteOpen(!isAdvisorCommitteOpen);
    setIsInstrumentOpen(false);
    setIsStaffMembersOpen(false);
    setIsPublicationsOpen(false);
    setIsNewsOpen(false);
    setIsCareersOpen(false);
    setIsEquipmentsOpen(false);
    setIsCapabilitiesOpen(false);
  };
  const handlePublicationsToggle = () => {
    setIsPublicationsOpen(!isPublicationsOpen);
    setIsInstrumentOpen(false);
    setIsStaffMembersOpen(false);
    setIsAdvisorCommiteOpen(false);
    setIsNewsOpen(false);
    setIsCareersOpen(false);
    setIsEquipmentsOpen(false);
    setIsCapabilitiesOpen(false);
  };
  const handleNewsToggle = () => {
    setIsNewsOpen(!isNewsOpen);
    setIsInstrumentOpen(false);
    setIsStaffMembersOpen(false);
    setIsAdvisorCommiteOpen(false);
    setIsPublicationsOpen(false);
    setIsCareersOpen(false);
    setIsEquipmentsOpen(false);
    setIsCapabilitiesOpen(false);
  };
  const handleCareersToggle = () => {
    setIsCareersOpen(!isCareersOpen);
    setIsNewsOpen(false);
    setIsInstrumentOpen(false);
    setIsStaffMembersOpen(false);
    setIsAdvisorCommiteOpen(false);
    setIsPublicationsOpen(false);
    setIsEquipmentsOpen(false);
    setIsCapabilitiesOpen(false);
  };
  const handleEquipmentsToggle = () => {
    setIsEquipmentsOpen(!isEquipmentsOpen);
    setIsCareersOpen(false);
    setIsNewsOpen(false);
    setIsInstrumentOpen(false);
    setIsStaffMembersOpen(false);
    setIsAdvisorCommiteOpen(false);
    setIsPublicationsOpen(false);
    setIsCapabilitiesOpen(false);
  };
  const handleCapabilitiesToggle = () => {
    setIsCapabilitiesOpen(!isCapabilitiesOpen);
    setIsEquipmentsOpen(false);
    setIsCareersOpen(false);
    setIsNewsOpen(false);
    setIsInstrumentOpen(false);
    setIsStaffMembersOpen(false);
    setIsAdvisorCommiteOpen(false);
    setIsPublicationsOpen(false);
  };

  const handleRequestsToggle = () => {
    setIsRequestsOpen(!isRequestsOpen);
  };

  const handleDepartmentsToggle = () => {
    setIsDepartmentsOpen(!isDepartmentsOpen);
    setIsInstrumentsOpen(false);
    setIsInstrumentWebPageOpen(false);
    setIsWebPageOpen(false);
  };
  const handleEventsToggle = () => {
    setIsEventsOpen(!isEventsOpen);
  };
  const handleCalendarToggle = () => {
    setIsCalendarsOpen(!isCalendarsOpen);
    setIsPIcustomersOpen(false);
    setIsInstrumentsOpen(false);
    setIsUserOpen(false);
    setIsCenterInformationOpen(false);
    setIsInstrumentWebPageOpen(false);
    setIsWebPageOpen(false);
  };

  const handleProjectsToggle = () => {
    setIsProjectsOpen(!isProjectsOpen);
  };
  const handleCenterInformationToggle = () => {
    setIsCenterInformationOpen(!isCenterInformationOpen);
    setIsCalendarsOpen(false);
    setIsPIcustomersOpen(false);
    setIsInstrumentsOpen(false);
    setIsUserOpen(false);
    setIsInstrumentWebPageOpen(false);
    setIsWebPageOpen(false);
  };
  const handleManageClick = () => {
    setIsCalendarsOpen(false);
    setIsPIcustomersOpen(false);
    setIsInstrumentsOpen(false);
    setIsUserOpen(false);
    setIsCenterInformationOpen(false);
    setIsInstrumentWebPageOpen(false);
    setIsWebPageOpen(false);
  };
  const handleInstrumentToggle = () => {
    setIsInstrumentsOpen(!isInstrumentsOpen);
    setIsCalendarsOpen(false);
    setIsPIcustomersOpen(false);
    setIsUserOpen(false);
    setIsCenterInformationOpen(false);
    setIsWebPageOpen(false);
  };
  const handleServiceToggle = () => {
    setIsServicesOpen(!isServicesOpen);
  };
  const handleComputerInfoToggle = () => {
    setIsComputerInfoOpen(!isComputerInfoOpen);
  };
  const handleUserToggle = () => {
    setIsUserOpen(!isUsersOpen);
    setIsInstrumentsOpen(false);
    setIsCalendarsOpen(false);
    setIsPIcustomersOpen(false);
    setIsInstrumentWebPageOpen(false);
    setIsWebPageOpen(false);
  };

  return (
    <Menu preventOverflow="true">
      <MenuButton color="white" onClick={handleManageClick}>
        {" "}
        <LinkText color={props.color}>Manage</LinkText>
      </MenuButton>
      <MenuList zIndex={10}>
        <MenuGroup
          title={
            <Flex justifyContent="space-between" alignItems="center">
              <Text>PI/Customers</Text>
              {isPIcustomersOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          }
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handlePIcustomersToggle}
          bg="gray.200"
        >
          {isPIcustomersOpen && (
            <>
              <MenuItem
                as="a"
                href="/manage/manage-current-customers"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
                pl={100}
              >
                Current
              </MenuItem>
              <MenuItem
                as="a"
                href="/add-customer"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                New PI / Customer
              </MenuItem>
              <MenuItem
                as="a"
                href="/manage/manage-archive-customers"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Archived
              </MenuItem>
              <MenuItem
                as="a"
                href="/donation"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Donor Dashboard
              </MenuItem>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Schools</Text>
                    {isSchoolsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </Flex>
                }
                onClick={handleSchoolsToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isSchoolsOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/schools/manage-school"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Manage School
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/schools/add-school"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add New School
                    </MenuItem>
                  </>
                )}
              </MenuGroup>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Departments</Text>
                    {isDepartmentsOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                onClick={handleDepartmentsToggle}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                style={{ paddingLeft: 20 }}
                placement="rtl"
              >
                {isDepartmentsOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/departments/manage-departments"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Manage Departments
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/departments/add-department"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add New Department
                    </MenuItem>
                  </>
                )}
              </MenuGroup>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title={
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Calendar</Text>
              {isCalendarsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          }
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleCalendarToggle}
          bg="gray.200"
        >
          {isCalendarsOpen && (
            <>
              <MenuItem
                as="a"
                href="/under-construction"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Manage Cancellation
              </MenuItem>
              {classLevel === 1 && (
                <MenuItem
                  as="a"
                  href="/under-construction"
                  onClick={props.onClick}
                  style={{
                    paddingLeft: 20,
                    textDecoration: "none",
                    fontSize: "10",
                    color: "inherit",
                    "&:hover": { textDecoration: "none", color: "inherit" },
                  }}
                >
                  Manage Reservation
                </MenuItem>
              )}
              {classLevel === 1 && (
                <MenuGroup
                  title={
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text>Events</Text>
                      {isEventsOpen ? (
                        <ChevronDownIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </Flex>
                  }
                  direction="rtl"
                  onClick={handleEventsToggle}
                  style={{ paddingLeft: 20 }}
                  fontWeight="bold"
                  fontSize={["lg", "lg", "md"]}
                  placement="rtl"
                >
                  {isEventsOpen && (
                    <>
                      <MenuItem
                        as="a"
                        href="/multiple-reservation"
                        onClick={props.onClick}
                        style={{
                          paddingLeft: 35,
                          textDecoration: "none",
                          fontSize: "5",
                          color: "inherit",
                          "&:hover": {
                            textDecoration: "none",
                            color: "inherit",
                          },
                        }}
                      >
                        View Event
                      </MenuItem>
                      <MenuItem
                        as="a"
                        href="/add-full-day-event"
                        onClick={props.onClick}
                        style={{
                          paddingLeft: 35,
                          textDecoration: "none",
                          fontSize: "5",
                          color: "inherit",
                          "&:hover": {
                            textDecoration: "none",
                            color: "inherit",
                          },
                        }}
                      >
                        Add Event
                      </MenuItem>
                    </>
                  )}
                </MenuGroup>
              )}
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title={
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Instruments</Text>
              {isInstrumentsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          }
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleInstrumentToggle}
          bg="gray.200"
        >
          {isInstrumentsOpen && (
            <>
              <MenuItem
                as="a"
                href="/instruments/instruments"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Current
              </MenuItem>

              {classLevel === 1 && (
                <MenuItem
                  as="a"
                  href="/instruments/add-instrument"
                  onClick={props.onClick}
                  style={{
                    paddingLeft: 20,
                    textDecoration: "none",
                    fontSize: "10",
                    color: "inherit",
                    "&:hover": { textDecoration: "none", color: "inherit" },
                  }}
                >
                  New Instrument
                </MenuItem>
              )}

              {classLevel === 1 && (
                <MenuItem
                  as="a"
                  href="/instruments/instrument-email-list"
                  onClick={props.onClick}
                  style={{
                    paddingLeft: 20,
                    textDecoration: "none",
                    fontSize: "10",
                    color: "inherit",
                    "&:hover": { textDecoration: "none", color: "inherit" },
                  }}
                >
                  Email Lists
                </MenuItem>
              )}
              {classLevel === 1 && (
                <MenuItem
                  as="a"
                  href="/under-construction"
                  onClick={props.onClick}
                  style={{
                    paddingLeft: 20,
                    textDecoration: "none",
                    fontSize: "10",
                    color: "inherit",
                    "&:hover": { textDecoration: "none", color: "inherit" },
                  }}
                >
                  Restricted Instruments
                </MenuItem>
              )}
              {classLevel === 1 && (
                <MenuItem
                  as="a"
                  href="/instruments/archived-instruments"
                  onClick={props.onClick}
                  style={{
                    paddingLeft: 20,
                    textDecoration: "none",
                    fontSize: "10",
                    color: "inherit",
                    "&:hover": { textDecoration: "none", color: "inherit" },
                  }}
                >
                  Archived Instruments
                </MenuItem>
              )}

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Service Reports</Text>
                    {isServicesOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                direction="rtl"
                onClick={handleServiceToggle}
                fontWeight="bold"
                style={{ paddingLeft: 20 }}
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {
                  //Added this condition to check if the user is a class level 1 user
                }
                {isServicesOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/instruments/add-service-reports"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add Report
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/instruments/view-service-reports"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      View Report
                    </MenuItem>
                  </>
                )}
              </MenuGroup>
              {classLevel === 1 && (
                <MenuItem
                  as="a"
                  href="/under-construction"
                  onClick={props.onClick}
                  style={{
                    paddingLeft: 20,
                    textDecoration: "none",
                    fontSize: "10",
                    color: "inherit",
                    "&:hover": { textDecoration: "none", color: "inherit" },
                  }}
                >
                  Computer Info
                </MenuItem>
              )}
              {classLevel === 1 && (
                <MenuItem
                  as="a"
                  href="/under-construction"
                  onClick={props.onClick}
                  style={{
                    paddingLeft: 20,
                    textDecoration: "none",
                    fontSize: "10",
                    color: "inherit",
                    "&:hover": { textDecoration: "none", color: "inherit" },
                  }}
                >
                  Service Category
                </MenuItem>
              )}
              {classLevel === 1 && (
                <MenuGroup
                  title="Computer Info"
                  direction="rtl"
                  onClick={handleComputerInfoToggle}
                  bg="gray.200"
                  fontWeight="bold"
                  fontSize={["lg", "lg", "md"]}
                  placement="rtl"
                >
                  {isComputerInfoOpen && (
                    <>
                      <MenuItem
                        as="a"
                        href="/under-construction"
                        onClick={props.onClick}
                        style={{
                          paddingLeft: 35,
                          textDecoration: "none",
                          fontSize: "5",
                          color: "inherit",
                          "&:hover": {
                            textDecoration: "none",
                            color: "inherit",
                          },
                        }}
                      >
                        Add Info
                      </MenuItem>
                      <MenuItem
                        as="a"
                        href="/under-construction"
                        onClick={props.onClick}
                        style={{
                          paddingLeft: 35,
                          textDecoration: "none",
                          fontSize: "5",
                          color: "inherit",
                          "&:hover": {
                            textDecoration: "none",
                            color: "inherit",
                          },
                        }}
                      >
                        Update Info
                      </MenuItem>
                    </>
                  )}
                </MenuGroup>
              )}
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title={
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Users</Text>
              {isUsersOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          }
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          bg="gray.200"
          onClick={handleUserToggle}
        >
          {isUsersOpen && (
            <>
              <MenuItem
                as="a"
                href="/manage/manage-current-users"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Current
              </MenuItem>
              <MenuItem
                as="a"
                href="/manage/manage-archived-users"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Archived
              </MenuItem>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Requests</Text>
                    {isRequestsOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                direction="rtl"
                onClick={handleRequestsToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isRequestsOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/manage/new-requests-users"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      New Requests
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/manage/new-training-requests"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Training Requests
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/manage/assistance-requests"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Assistance Requests
                    </MenuItem>
                  </>
                )}
              </MenuGroup>

              <MenuItem
                as="a"
                href="/manage/add-documents"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Upload Documents
              </MenuItem>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title={
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Projects</Text>
              {isProjectsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          }
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleProjectsToggle}
          bg="gray.200"
        >
          {isProjectsOpen && (
            <>
              <MenuItem
                as="a"
                href="/projects/cryo-em-access-request"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Cryo EM Submission
              </MenuItem>
              <MenuItem
                as="a"
                href="/projects/view-submission-cryo-em"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                View Cryo EM Submission
              </MenuItem>
              <MenuItem
                as="a"
                href="/projects/cryo-em-email"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Cryo EM Email
              </MenuItem>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title={
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Center Information</Text>
              {isCenterInformationOpen ? (
                <ChevronDownIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </Flex>
          }
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleCenterInformationToggle}
          bg="gray.200"
        >
          {isCenterInformationOpen && (
            <>
              <MenuItem
                as="a"
                href="/manage/permit"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Permit
              </MenuItem>
              <MenuItem
                as="a"
                //href="/manage/add-policy"
                href="/under-construction"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Add Policy Details
              </MenuItem>
              <MenuItem
                as="a"
                // href="/manage/view-policy"
                href="/under-construction"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                View Policy Details
              </MenuItem>
              <MenuItem
                as="a"
                href="/manage/manage-tutorial"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Tutorial
              </MenuItem>
              <MenuItem
                as="a"
                href="/manage/manage-manual"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Manual
              </MenuItem>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title={
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Web Page</Text>
              {isWebPageOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          }
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleWebPageToggle}
          bg="gray.200"
        >
          {isWebPageOpen && (
            <>
              <MenuItem
                as="a"
                href="/manage/update-equipment-donation"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Update Equipment Donation
              </MenuItem>

              <MenuItem
                as="a"
                href="/home-images-upload"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Update Home Page Image
              </MenuItem>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Instrument</Text>
                    {isInstrumentOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                direction="rtl"
                onClick={handleInstrumentsToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isInstrumentOpen && (
                  <>
                    <MenuGroup
                      title={
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text>Instrument Web Page</Text>
                          {isInstrumentWebPageOpen ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </Flex>
                      }
                      direction="rtl"
                      onClick={handleInstrumentWebPageToggle}
                      style={{ paddingLeft: 35 }}
                      fontWeight="bold"
                      fontSize={["lg", "lg", "md"]}
                      placement="rtl"
                    >
                      {isInstrumentWebPageOpen && (
                        <>
                          <MenuItem
                            as="a"
                            href="/add-instrument"
                            onClick={props.onClick}
                            style={{
                              paddingLeft: 50,
                              textDecoration: "none",
                              fontSize: "5",
                              color: "inherit",
                              "&:hover": {
                                textDecoration: "none",
                                color: "inherit",
                              },
                            }}
                          >
                            Add Instrument
                          </MenuItem>
                          <MenuItem
                            as="a"
                            href="/update-instrument"
                            onClick={props.onClick}
                            style={{
                              paddingLeft: 50,
                              textDecoration: "none",
                              fontSize: "5",
                              color: "inherit",
                              "&:hover": {
                                textDecoration: "none",
                                color: "inherit",
                              },
                            }}
                          >
                            Update Instrument
                          </MenuItem>
                          <MenuItem
                            as="a"
                            href="/update-equipment"
                            onClick={props.onClick}
                            style={{
                              paddingLeft: 50,
                              textDecoration: "none",
                              fontSize: "5",
                              color: "inherit",
                              "&:hover": {
                                textDecoration: "none",
                                color: "inherit",
                              },
                            }}
                          >
                            Update Sample Preparation
                          </MenuItem>
                          <MenuItem
                            as="a"
                            href="/archived-instruments"
                            onClick={props.onClick}
                            style={{
                              paddingLeft: 50,
                              textDecoration: "none",
                              fontSize: "5",
                              color: "inherit",
                              "&:hover": {
                                textDecoration: "none",
                                color: "inherit",
                              },
                            }}
                          >
                            Archived
                          </MenuItem>
                        </>
                      )}
                    </MenuGroup>
                  </>
                )}
              </MenuGroup>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Staff Members</Text>
                    {isStaffMembersOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                direction="rtl"
                onClick={handleStaffMembersToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isStaffMembersOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/staff-members/add-staff-member"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add Staff Member
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/staff-members/update-staff-member"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Update Staff Member
                    </MenuItem>
                  </>
                )}
              </MenuGroup>
              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Advisory Committee</Text>
                    {isAdvisorCommitteOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                direction="rtl"
                onClick={handleAdvisorCommitteToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isAdvisorCommitteOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/manage/add-committee-member"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add Committee Member
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/manage/edit-committee-member"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Update Committee Member
                    </MenuItem>
                  </>
                )}
              </MenuGroup>
              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Publications</Text>
                    {isPublicationsOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                direction="rtl"
                onClick={handlePublicationsToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isPublicationsOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/publications/manage-publication"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Manage Publications
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/publications/add-publication"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add Publications
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/publications/publication-report"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Publication Report
                    </MenuItem>
                  </>
                )}
              </MenuGroup>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>News</Text>
                    {isNewsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </Flex>
                }
                direction="rtl"
                onClick={handleNewsToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isNewsOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/under-construction"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add News
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/under-construction"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Update/ Delete News
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/under-construction"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Archived News
                    </MenuItem>
                  </>
                )}
              </MenuGroup>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Careers</Text>
                    {isCareersOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </Flex>
                }
                direction="rtl"
                onClick={handleCareersToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isCareersOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/under-construction"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add Careers
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/under-construction"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Update/ Delete Careers
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/under-construction"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Archived Careers
                    </MenuItem>
                  </>
                )}
              </MenuGroup>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Equipments</Text>
                    {isEquipmentsOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                direction="rtl"
                onClick={handleEquipmentsToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isEquipmentsOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/under-construction"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add Equipments
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/under-construction"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Manage Equipments
                    </MenuItem>
                  </>
                )}
              </MenuGroup>

              <MenuGroup
                title={
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>Capabilities</Text>
                    {isCapabilitiesOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                }
                direction="rtl"
                onClick={handleCapabilitiesToggle}
                style={{ paddingLeft: 20 }}
                fontWeight="bold"
                fontSize={["lg", "lg", "md"]}
                placement="rtl"
              >
                {isCapabilitiesOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/manage/add-capabilities"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add Capabilities
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/manage/manage-capabilities"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Manage Capabilities
                    </MenuItem>
                  </>
                )}
              </MenuGroup>
            </>
          )}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export const CustomerManageMenu = (props) => {
  const [isPIcustomersOpen, setIsPIcustomersOpen] = useState(false);
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);

  const LinkText = ({ children, color, ...props }) => {
    const colorValue = color;
    return (
      <Text
        as="span"
        fontSize={["lg", "lg", "md"]}
        fontWeight="bold"
        color={colorValue}
        {...props}
      >
        {children}
      </Text>
    );
  };

  const handlePIcustomersToggle = () => {
    setIsPIcustomersOpen(!isPIcustomersOpen);
  };

  const handleManageClick = () => {
    setIsPIcustomersOpen(false);
  };

  const handleProjectsToggle = () => {
    setIsProjectsOpen(!isProjectsOpen);
  };

  return (
    <Menu preventOverflow="true">
      <MenuButton color="white" onClick={handleManageClick}>
        {" "}
        <LinkText color={props.color}>Center Information</LinkText>
      </MenuButton>
      <MenuList zIndex={10}>
        <MenuGroup
          title="Training"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handlePIcustomersToggle}
        >
          {isPIcustomersOpen && (
            <>
              <MenuItem
                as="a"
                href="../center-info/new-user-tutorial"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Tutorial
              </MenuItem>
              <MenuItem
                as="a"
                href="../center-info/my-scope"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                MyScope
              </MenuItem>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title="Projects"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleProjectsToggle}
        >
          {isProjectsOpen && (
            <>
              <MenuItem
                as="a"
                href="/projects/cryo-em-access-request"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Cryo EM Submission
              </MenuItem>
              {props.Customer &&
                props.CryoCommitteeList &&
                props.Customer.email &&
                props.CryoCommitteeList.includes(props.Customer.email) && (
                  <MenuItem
                    as="a"
                    href="/projects/view-submission-cryo-em"
                    onClick={props.onClick}
                    style={{
                      paddingLeft: 20,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    View Cryo EM Submission
                  </MenuItem>
                )}
            </>
          )}
        </MenuGroup>

        <MenuItem
          as="a"
          href="../center-info/new-user-manual"
          onClick={props.onClick}
          style={{
            paddingLeft: 20,
            textDecoration: "none",
            fontSize: "10",
            color: "inherit",
            "&:hover": { textDecoration: "none", color: "inherit" },
          }}
        >
          <LinkText> Manual</LinkText>
        </MenuItem>

        <MenuItem
          as="a"
          href="/records/view-record"
          onClick={props.onClick}
          style={{
            paddingLeft: 20,
            textDecoration: "none",
            fontSize: "10",
            color: "inherit",
            "&:hover": { textDecoration: "none", color: "inherit" },
          }}
        >
          <LinkText> Find Records</LinkText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
